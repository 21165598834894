import React from "react";
import { Helmet } from "react-helmet";
import Layout from "../components/Layout";

const Home = () => {
    return (
        <Layout>
            <Helmet>
                <title>AR DigiTrail</title>
                <meta
                    name="description"
                    content="Glasgow Science Centre - AR DigiTrail"
                />
                <link rel="canonical" href="/" />
            </Helmet>
            <div className="container">
                <div className="row mt-4">
                    <div className="col-sm-6 offset-sm-3 welcome-panel">
                        <h1>Welcome</h1>
                        <p>
                            Welcome to the Glasgow Science Centre Augmented
                            Reality DigiTrail!
                        </p>
                        <p>
                            Visitor are invited to find the answer to the questions on the big screen next to the planetarium entrance.
                            The answers can be found by scanning the QR codes dotted around the floor of the gallery.
                        </p>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default Home;
